import { Fragment } from "react";
import Header from "./header";
import Footer from "./footer";
import "../Style/Team.css";
import Team from "../../Images/Team.png";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <div>
      <Helmet>
        <title>Our Team - ETSquares Health</title>
        <meta name="description" content=""></meta>
      </Helmet>
      <Fragment>
        <Header />
        <div id="breadcrumb">
          <div className="container">
            <ul>
              <li>Meet The Team</li>
            </ul>
          </div>
        </div>
        <div className="container margin_60">
          <section id="team" className="section pb-5">
            <div className="container">
              <h5 className="section section-title h1">OUR TEAM</h5>
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="image-flip">
                    <div className="mainflip flip-0">
                      <div className="frontside">
                        <div className="card">
                          <div className="card-body text-center">
                            <p>
                              <img
                                className=" img-fluid"
                                src={Team}
                                alt="card image"
                              />
                            </p>
                            <h4 className="card-title">
                              Dr. Gyanendra Singh (PT)
                            </h4>
                            <p className="card-text">BPT MIAP CDCP CIASTMP</p>
                            <a href="#" className="btn btn-primary btn-sm">
                              <i className="fa fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="backside">
                        <div className="card">
                          <div className="card-body text-center mt-4">
                            <h4 className="card-title">
                              Dr. Gyanendra Singh(PT)
                            </h4>
                            <p className="card-text">
                              He is an All India Certified Physiotherapist
                              having around 7 years of clinical experience.He is
                              qualified with BPT, MIAP, CDCP & CIASTMP.
                            </p>
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-skype"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-google"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div
                    className="image-flip"
                    ontouchstart="this.classList.toggle('hover');"
                  >
                    <div className="mainflip">
                      <div className="frontside">
                        <div className="card">
                          <div className="card-body text-center">
                            <p>
                              <img
                                className=" img-fluid"
                                src={Team}
                                alt="card image"
                              />
                            </p>
                            <h4 className="card-title">Dr. Rahul Singh(PT)</h4>
                            <p className="card-text">BPT MIAP CDCP CIASTMP</p>
                            <a href="#" className="btn btn-primary btn-sm">
                              <i className="fa fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="backside">
                        <div className="card">
                          <div className="card-body text-center mt-4">
                            <h4 className="card-title">Dr. Rahul Singh (PT)</h4>
                            <p className="card-text">
                              He has around 7 years of clinical experience.He is
                              qualified with BPT, MIAP, CDCP & CIASTMP.
                            </p>
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-skype"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-google"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div
                    className="image-flip"
                    ontouchstart="this.classList.toggle('hover');"
                  >
                    <div className="mainflip">
                      <div className="frontside">
                        <div className="card">
                          <div className="card-body text-center">
                            <p>
                              <img
                                className=" img-fluid"
                                src={Team}
                                alt="card image"
                              />
                            </p>
                            <h4 className="card-title">
                              Dr. Ram Lakhan Yadav(PT)
                            </h4>
                            <p className="card-text">BPT</p>
                            <a href="#" className="btn btn-primary btn-sm">
                              <i className="fa fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="backside">
                        <div className="card">
                          <div className="card-body text-center mt-4">
                            <h4 className="card-title">
                              Dr. Ram Lakhan Yadav (PT)
                            </h4>
                            <p className="card-text">
                              He has around 2 years of clinical experience.He is
                              qualified with BPT.
                            </p>
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-skype"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-google"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div
                    className="image-flip"
                    ontouchstart="this.classList.toggle('hover');"
                  >
                    <div className="mainflip">
                      <div className="frontside">
                        <div className="card">
                          <div className="card-body text-center">
                            <p>
                              <img
                                className=" img-fluid"
                                src={Team}
                                alt="card image"
                              />
                            </p>
                            <h4 className="card-title">Dr. Sonali Singh(PT)</h4>
                            <p className="card-text">BPT</p>
                            <a href="#" className="btn btn-primary btn-sm">
                              <i className="fa fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="backside">
                        <div className="card">
                          <div className="card-body text-center mt-4">
                            <h4 className="card-title">Dr. Sonali Singh(PT)</h4>
                            <p className="card-text">
                              She has around 2 years of clinical experience.He
                              is qualified with BPT.
                            </p>
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-skype"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-google"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div
                    className="image-flip"
                    ontouchstart="this.classList.toggle('hover');"
                  >
                    <div className="mainflip">
                      <div className="frontside">
                        <div className="card">
                          <div className="card-body text-center">
                            <p>
                              <img
                                className=" img-fluid"
                                src={Team}
                                alt="card image"
                              />
                            </p>
                            <h4 className="card-title">Dr. Avnish Kumar(PT)</h4>
                            <p className="card-text">BPT</p>
                            <a href="#" className="btn btn-primary btn-sm">
                              <i className="fa fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="backside">
                        <div className="card">
                          <div className="card-body text-center mt-4">
                            <h4 className="card-title">Dr. Avnish Kumar(PT)</h4>
                            <p className="card-text">
                              He has around 2 years of clinical experience.He is
                              qualified with BPT.
                            </p>
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-skype"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-google"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div
                    className="image-flip"
                    ontouchstart="this.classList.toggle('hover');"
                  >
                    <div className="mainflip">
                      <div className="frontside">
                        <div className="card">
                          <div className="card-body text-center">
                            <p>
                              <img
                                className=" img-fluid"
                                src={Team}
                                alt="card image"
                              />
                            </p>
                            <h4 className="card-title">Dr. Kajal Sharma(PT)</h4>
                            <p className="card-text">BPT</p>
                            <a href="#" className="btn btn-primary btn-sm">
                              <i className="fa fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="backside">
                        <div className="card">
                          <div className="card-body text-center mt-4">
                            <h4 className="card-title">Dr. Kajal Sharma(PT)</h4>
                            <p className="card-text">
                              She has around 2 years of clinical experience.He
                              is qualified with BPT.
                            </p>
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-skype"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  className="social-icon text-xs-center"
                                  target="_blank"
                                  href="#"
                                >
                                  <i className="fa fa-google"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </Fragment>
    </div>
  );
};
