import ETTLogo from "../../Images/EternalTechTherapy.png";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import "./cornerButton.css";

export default () => {
  const [show, setShow] = useState("");
  const [close, setClose] = useState("");
  const [id, setId] = useState("open");
  const [serviceMenu, setServiceMenu] = useState(false);

  const handleServiceMenu = () => {
    setServiceMenu(!serviceMenu);
  };

  const showMobileView = (e) => {
    // if (e.target.id === "open") {
    setShow("show");
    setClose("active");
    setId("close");
    // } else {
    //   setShow("");
    //   setClose("");
    //   setId("open");
    // }
  };

  return (
    <header className="header_sticky">
      <div className="container">
        <div className="mobile_menu_div" style={{ display: "none" }}>
          <div className="row">
            <div className="col-4 text-left">
              <a
                className={`cmn-toggle-switch cmn-toggle-switch__htx open_close ${close}`}
                href="#0"
                id={`${id}`}
                onClick={(e) => {
                  showMobileView(e);
                }}
              >
                <span>Menu mobile</span>
              </a>
              <div className={`main-menu main-menu__width ${show}`}>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>

                  <li className="submenu">
                    <a href="#0" className="show-submenu">
                      Offerings<i className="fa fa-caret-right"></i>
                    </a>
                    <ul className="show_normal">
                      <li>
                        <NavLink
                          to="/therapy"
                          className={
                            window.location.pathname === "/therapy"
                              ? "active-class"
                              : ""
                          }
                        >
                          Our Therapies
                        </NavLink>
                      </li>
                      <li className="submenu">
                        <a
                          href="#0"
                          className="show-submenu"
                          onClick={handleServiceMenu}
                        >
                          Our Services<i className="fa fa-caret-right"></i>
                        </a>
                        <ul className={serviceMenu ? "show_normal" : ""}>
                          <li>
                            <NavLink
                              to="/back-pain-neck-pain-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/back-pain-neck-pain-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Back Pain And Neck Pain Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/chest-physiotherapy-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/chest-physiotherapy-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Chest Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/knee-pain-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/knee-pain-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Knee Pain Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/migraine-physiotherapy-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/migraine-physiotherapy-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Migraine Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-parkinson-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-parkinson-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Parkinson Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/pediatric-rehabilitation-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/pediatric-rehabilitation-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Pediatric Rehabilitation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-speech-therapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-speech-therapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Speech Therapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-sports-physiotherapy-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-sports-physiotherapy-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Sports Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/tennis-elbow-pain-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/tennis-elbow-pain-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Tennis Elbow Pain Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/vertigo-physiotherapy-lucknow"
                              className={
                                window.location.pathname ===
                                "/vertigo-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Vertigo Physiotherapy
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to="/about"
                      className={
                        window.location.pathname === "/about"
                          ? "active-class"
                          : ""
                      }
                    >
                      About Us{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/faq"
                      className={
                        window.location.pathname === "/faq"
                          ? "active-class"
                          : ""
                      }
                    >
                      FAQ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      className={
                        window.location.pathname === "/contact"
                          ? "active-class"
                          : ""
                      }
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/ourteams"
                      className={
                        window.location.pathname === "/ourteams"
                          ? "active-class"
                          : ""
                      }
                    >
                      Meet The Team
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-4 text-center">
              <a href="#" className="header_logo_a">
                <img src={ETTLogo} width="105" alt="logo" />
              </a>
            </div>
            <div className="col-4 text-right">
              <ul id="m_top_access">
                <li>
                  <a href="javascript:void(0)" className="floating_btn">
                    <i className="pe-7s-map-marker"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="desktop_menu_div">
          <div className="row align-items-center">
            <div className="col-lg-2 col-6">
              <NavLink to="/">
                <img
                  src={ETTLogo}
                  className="img-fluid"
                  width="80"
                  alt="main_logo.webp"
                />
              </NavLink>
            </div>
            <nav className="col-lg-10 col-6">
              <a
                className="cmn-toggle-switch cmn-toggle-switch__htx open_close"
                href="javascript:void(0)"
              >
                <span>Menu mobile</span>
              </a>
              <ul id="top_access">
                <li>
                  <a
                    href="javascript:void(0)"
                    className="floating_btn"
                    data-type=""
                  >
                    <i className="pe-7s-map-marker"></i>
                  </a>
                </li>
              </ul>
              <div className="main-menu">
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>

                  <li className="submenu">
                    <a href="#0" className="show-submenu">
                      Offerings<i className="icon-down-open-mini"></i>
                    </a>
                    <ul>
                      <li>
                        <NavLink
                          to="/therapy"
                          className={
                            window.location.pathname === "/therapy"
                              ? "active-class"
                              : ""
                          }
                        >
                          Our Therapies
                        </NavLink>
                      </li>
                      <li className="submenu">
                        <a href="#0" className="show-submenu">
                          Our Services<i className="icon-down-open-mini"></i>
                        </a>
                        <ul>
                          <li>
                            <NavLink
                              to="/back-pain-neck-pain-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/back-pain-neck-pain-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Back Pain And Neck Pain Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/chest-physiotherapy-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/chest-physiotherapy-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Chest Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/knee-pain-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/knee-pain-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Knee Pain Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/migraine-physiotherapy-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/migraine-physiotherapy-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Migraine Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-parkinson-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-parkinson-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Parkinson Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/pediatric-rehabilitation-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/pediatric-rehabilitation-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Pediatric Rehabilitation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-speech-therapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-speech-therapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Speech Therapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/best-sports-physiotherapy-clinic-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/best-sports-physiotherapy-clinic-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Sports Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/tennis-elbow-pain-physiotherapy-lucknow"
                              style={{ borderBottom: "1px solid #ededed" }}
                              className={
                                window.location.pathname ===
                                "/tennis-elbow-pain-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Tennis Elbow Pain Physiotherapy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/vertigo-physiotherapy-lucknow"
                              className={
                                window.location.pathname ===
                                "/vertigo-physiotherapy-lucknow"
                                  ? "active-class"
                                  : ""
                              }
                            >
                              Vertigo Physiotherapy
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to="/about"
                      className={
                        window.location.pathname === "/about"
                          ? "active-class"
                          : ""
                      }
                    >
                      About Us{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/faq"
                      className={
                        window.location.pathname === "/faq"
                          ? "active-class"
                          : ""
                      }
                    >
                      FAQ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      className={
                        window.location.pathname === "/contact"
                          ? "active-class"
                          : ""
                      }
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/ourteams"
                      className={
                        window.location.pathname === "/ourteams"
                          ? "active-class"
                          : ""
                      }
                    >
                      Meet The Team
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};
