import Header from '../header'
import Footer from '../footer'
import './serviceOffered.css'
import Physiotherapy from '../../../Images/Physiotherapy_Service.png'

export default () => {
    return (
        <>
            <Header />
            <div id="breadcrumb">
                <div className="container">
                    <ul>
                        <li>ETSquares Services</li>
                    </ul>
                </div>
            </div>
            <div className="container margin_60">
                <div className="container">
                    {/* <div className="page-header text-center">
                        <h1 id="timeline">ETSquares Services</h1>
                    </div> */}
                    <ul className="timeline">
                        <li>
                            <div className="timeline-badge primary"><a><i className="fa fa-dot-circle-o" rel="tooltip" title="physiotherapy" id=""></i></a></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    {/* <img className="img-responsive" src={Physiotherapy} height={"60px"} width={"120px"} /> */}
                                </div>
                                <div className="timeline-body">
                                    <p style={{ color: "#3931af" }}><strong>Physiotherapy</strong> helps to restore movement and function when someone is affected by injury, illness or disability. It can also help to reduce your risk of injury or illness in the future. It takes a holistic approach that involves the patient directly in their own care.</p>
                                </div>
                                <div className="timeline-footer">
                                    {/* <a><i className="glyphicon glyphicon-thumbs-up"></i></a> */}
                                    <a><i className="fa fa-hand-o-right"></i></a>
                                    <a className="pull-right" style={{ color: '#ffa500' }}>Physiotherapy</a>
                                </div>
                            </div>
                        </li>

                        <li className="timeline-inverted">
                            <div className="timeline-badge primary"><a><i className="fa fa-dot-circle-o" rel="tooltip" title="yoga" id=""></i></a></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">

                                    {/* <img className="img-responsive" src="http://lorempixel.com/1600/500/sports/2" /> */}

                                </div>
                                <div className="timeline-body">
                                    <p style={{ color: "#3931af" }}><strong>Yoga</strong> is a group of physical, mental, and spiritual practices or disciplines which originated in ancient India and aim to control (yoke) and still the mind, recognizing a detached witness-consciousness untouched by the mind (Chitta) and mundane suffering (Duḥkha). There is a wide variety of yoga, practices, and goals in etsquares health  using traditional and modern yoga practices.</p>

                                </div>

                                <div className="timeline-footer">
                                    {/* <a><i className="glyphicon glyphicon-thumbs-up"></i></a> */}
                                    <a><i className="fa fa-hand-o-right"></i></a>
                                    <a className="pull-right" style={{ color: '#ffa500' }}>Yoga</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-badge primary"><a><i className="fa fa-dot-circle-o" rel="tooltip" title="zumba" id=""></i></a></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    {/* <img className="img-responsive" src="http://lorempixel.com/1600/500/sports/2" /> */}

                                </div>
                                <div className="timeline-body">
                                    <p style={{ color: "#3931af" }}><strong>Zumba</strong> classes combine latin and international music and dance to create a dynamic calorie-burning form of workout for people of all fitness levels and age groups. This effective fitness system features aerobic training that is a fusion of slow and fast rhythms to tone and sculpt your body.</p>

                                </div>

                                <div className="timeline-footer">
                                    {/* <a><i className="glyphicon glyphicon-thumbs-up"></i></a> */}
                                    <a><i className="fa fa-hand-o-right"></i></a>
                                    <a className="pull-right" style={{ color: '#ffa500' }}>Zumba</a>
                                </div>
                            </div>
                        </li>

                        <li className="timeline-inverted">
                            <div className="timeline-badge primary"><a><i className="fa fa-dot-circle-o" rel="tooltip" title="diet control" id=""></i></a></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    {/* <img className="img-responsive" src="http://lorempixel.com/1600/500/sports/2" /> */}

                                </div>
                                <div className="timeline-body">
                                    <p style={{ color: "#3931af" }}><strong>Dieting</strong>, regulating one's food intake for the purpose of improving one's physical condition, especially for the purpose of reducing obesity, or what is conceived to be excess body fat.</p>

                                </div>

                                <div className="timeline-footer">
                                    {/* <a><i className="glyphicon glyphicon-thumbs-up"></i></a> */}
                                    <a><i className="fa fa-hand-o-right"></i></a>
                                    <a className="pull-right" style={{ color: '#ffa500' }}>Diet Control</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-badge primary"><a><i className="fa fa-dot-circle-o" rel="tooltip" title="cardio" id=""></i></a></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    {/* <img className="img-responsive" src="http://lorempixel.com/1600/500/sports/2" /> */}

                                </div>
                                <div className="timeline-body">
                                    <p style={{ color: "#3931af" }}><strong>Cardio</strong> improves many aspects of health, including heart health, mental health, mood, sleep, weight regulation and metabolism. Actually, the heart becomes more efficient with every beat as it pumps oxygen-carrying blood, the lungs more effective in taking in oxygen, and the muscles more equipped to use more oxygen.</p>

                                </div>

                                <div className="timeline-footer">
                                    {/* <a><i className="glyphicon glyphicon-thumbs-up"></i></a> */}
                                    <a><i className="fa fa-hand-o-right"></i></a>
                                    <a className="pull-right" style={{ color: '#ffa500' }}>Cardio</a>
                                </div>
                            </div>
                        </li>

                        <li className="timeline-inverted">
                            <div className="timeline-badge primary"><a><i className="fa fa-dot-circle-o" rel="tooltip" title="11 hours ago via Twitter" id=""></i></a></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    {/* <img className="img-responsive" src="http://lorempixel.com/1600/500/sports/2" /> */}

                                </div>
                                <div className="timeline-body">
                                    <p style={{ color: "#3931af" }}><strong>Special education</strong>, also called special needs education, the education of children who differ socially, mentally, or physically from the average to such an extent that they require modifications of usual school practices.</p>

                                </div>

                                <div className="timeline-footer primary">
                                    {/* <a><i className="glyphicon glyphicon-thumbs-up"></i></a> */}
                                    <a><i className="fa fa-hand-o-right"></i></a>
                                    <a className="pull-right" style={{ color: '#ffa500' }}>Special Education</a>
                                </div>
                            </div>
                        </li>


                    </ul>
                </div>
            </div>
            <hr />
            <Footer />
        </>
    )
}