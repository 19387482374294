export const Pediatric = {
  BACK_PAIN_HEADER_TEXT:
    "Empowering Children: Pediatric Rehabilitation Clinic in Lucknow",
  BACK_PAIN_CONTENT_TEXT:
    "Children facing physical, developmental, or cognitive challenges require specialized care to reach their fullest potential. In Lucknow, families can turn to pediatric rehabilitation clinics for comprehensive services aimed at improving the functional abilities and quality of life of children with disabilities or injuries. These clinics offer a range of therapies tailored to the unique needs of each child, providing support, encouragement, and hope for a brighter future. Let's explore the significance, benefits, and availability of pediatric rehabilitation clinics in Lucknow.",
  BACK_PAIN_UNDERSTANDING_HEADER_TEXT:
    "Understanding Pediatric Rehabilitation!",
  BACK_PAIN_UNDERSTANDING_CONTENT_TEXT:
    "<p>Pediatric rehabilitation focuses on maximizing the functional abilities and independence of children with disabilities, injuries, or developmental delays. It encompasses a multidisciplinary approach, involving physiotherapists, occupational therapists, speech-language pathologists, psychologists, and other healthcare professionals working together to address the physical, cognitive, emotional, and social needs of children and their families.</p>",
  COMPREHENSIVE_BACK_PAIN_HEADER_TEXT:
    "The Importance of Pediatric Rehabilitation Clinics",
  COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT:
    "<p>Pediatric rehabilitation clinics play a vital role in the lives of children with disabilities or injuries by providing early intervention, specialized therapies, and family-centered care. These clinics offer a supportive and nurturing environment where children can receive individualized attention and personalized treatment plans tailored to their specific needs and goals. Through a combination of therapies and interventions, pediatric rehabilitation clinics empower children to overcome challenges, develop new skills, and achieve greater independence.</p>",
  WHY_CHOOSE_US_HEADER_TEXT:
    "Finding the Best Pediatric Rehabilitation Clinic in Lucknow",
  WHY_CHOOSE_US_SUBHEADER_TEXT: "",
  WHY_CHOOSE_US_CONTENT_TEXT:
    "<p>In Lucknow, families seeking the best pediatric rehabilitation clinic can explore various options to find the right fit for their child's needs. The best pediatric rehabilitation clinics in Lucknow distinguish themselves through experienced and compassionate healthcare professionals, comprehensive services, state-of-the-art facilities, and a commitment to excellence in pediatric care. When searching for a pediatric rehabilitation clinic, consider factors such as the clinic's reputation, patient reviews, range of services offered, and dedication to patient and family-centered care.</p>",
  FIRST_STEP_HEADER_TEXT: "Benefits of Pediatric Rehabilitation",
  FIRST_STEP_CONTENT_TEXT:
    "<p><h5>Improved Functional Abilities:</h5> Pediatric rehabilitation helps children improve their motor skills, coordination, balance, and mobility through specialized therapies and interventions.</p><p><h5>Enhanced Independence:</h5> By addressing functional limitations and teaching adaptive techniques, pediatric rehabilitation promotes independence in daily activities and routines.</p><p><h5>Optimized Development:</h5> Early intervention and targeted therapies support children's cognitive, social, and emotional development, laying the foundation for future success.</p><p><h5>Support for Families:</h5> Pediatric rehabilitation clinics provide families with education, resources, and support networks to navigate their child's journey and advocate for their needs.</p><p><h5>Improved Quality of Life:</h5> By addressing physical, developmental, or cognitive challenges, pediatric rehabilitation enhances children's overall quality of life and well-being, enabling them to participate more fully in school, home, and community activities.</p>",
  FAQS_HEADER_TEXT: "Frequently Asked Questions (FAQs)",
  FAQS_CONTENT_TEXT:
    "<p><h5>1.	What sets apart the best pediatric rehabilitation clinic in Lucknow from other providers?</h5></p><p>The best pediatric rehabilitation clinic in Lucknow distinguishes itself through experienced and multidisciplinary healthcare professionals, comprehensive services, family-centered care, and a commitment to excellence in pediatric rehabilitation.</p><p><h5>2. How can I find the leading pediatric rehabilitation centre in Lucknow for my child?</h5></p><p>When seeking the leading pediatric rehabilitation centre in Lucknow, consider factors such as the centre's expertise in pediatric care, range of services offered, patient testimonials, and commitment to patient and family-centered care.</p><p><h5>3.	What types of therapies are offered at top-rated pediatric rehabilitation clinics in Lucknow?</h5></p><p>Top-rated pediatric rehabilitation clinics in Lucknow offer a range of therapies, including physiotherapy, occupational therapy, speech therapy, psychological support, and recreational therapy, tailored to the unique needs of each child.</p><p><h5>4.	How do pediatric rehabilitation clinics in Lucknow develop personalized treatment plans for children?</h5></p><p>Pediatric rehabilitation clinics in Lucknow conduct thorough assessments to evaluate each child's strengths, challenges, and goals. Based on this assessment, multidisciplinary teams develop personalized treatment plans that address the child's specific needs and maximize their potential for growth and development.</p><p><h5>5.	What are the potential benefits of pediatric rehabilitation for children receiving care at these clinics in Lucknow?</h5></p><p>Pediatric rehabilitation offers numerous benefits, including improved functional abilities, enhanced independence, optimized development, support for families, and an improved quality of life for children facing physical, developmental, or cognitive challenges.</p>",
};
