import FaqOne from "../Toggle/FaqOne";
import { ContentDetails } from "../../Enums/contentEnum";
export default () => {
  const data = [
    {
      title: ContentDetails.WHAT_IS_PHYSIOTHERAPY_HEADING,
      description: ContentDetails.WHAT_IS_PHYSIOTHERAPY_TEXT,
    },
    {
      title: ContentDetails.ISSUE_PHYSIO_CAN_TREAT_HEADING,
      description: ContentDetails.ISSUE_PHYSIO_CAN_TREAT_TEXT,
    },
    {
      title: ContentDetails.PROCEDURE_PHYSIO_HEADING,
      description: ContentDetails.PROCEDURE_PHYSIO_TEXT,
    },
    {
      title: ContentDetails.SPECIALIAZATION_THERAPY_HEADING,
      description: ContentDetails.SPECIALIAZATION_THERAPY_TEXT,
    },
  ];
  return (
    <>
      {data.map((d) => {
        return <FaqOne title={d.title} description={d.description} />;
      })}
    </>
  );
};
