import { useState } from "react";

const FaqFive = ({ title, description }) => {
  const [toggleThisElement, setToggleThisElement] = useState(false);
  return (
    <>
      <div className="panel panel-default">
        <div className="panel-heading" style={{ cursor: "pointer" }}>
          <h4
            className="panel-title"
            onClick={() => setToggleThisElement((prev) => !prev)}
          >
            <i className="fa fa-question-circle"></i>&nbsp; {title}
          </h4>
        </div>

        {toggleThisElement && (
          <div
            className="panel-body"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        )}
      </div>
    </>
  );
};

export default FaqFive;
