import React, { Component } from 'react'
import Header from '../header'
import Footer from '../footer'
import './carrerStyle.css'

class Carrer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <Header />
                <div id="breadcrumb">
                    <div className="container">
                        <ul>
                            <li>Carrers</li>
                        </ul>
                    </div>
                </div>
                <div className="container margin_60">
                    <section id="sectionJobList" class="section section-job-list gradient-light--lean-left">
                        <div class="container">

                            <div class="row row-grid justify-content-center">

                                <div class="col-md-8 col-lg-7 col-xl-6 text-center">
                                    <h2 class="section__title mb-4">Jobs Openings at ETSquares</h2>

                                    <p>We’re always searching for amazing people to join our team. Take a look at our current openings.</p>

                                </div>

                                <div class="col-md-10">

                                    <form class="filter-form mt-5 mb-4">
                                        <div class="row">

                                            <div class="col-md-4 mb-3">
                                                <div class="form-group">
                                                    <label for="jobPosition">Position :</label>
                                                    <select id="jobPosition" class="custom-select">
                                                        <option value="position1">Business</option>
                                                        <option value="position2">Design</option>
                                                        <option value="position3">Development</option>
                                                        <option value="position4">Engineering</option>
                                                        <option value="position5">Finance</option>
                                                        <option value="position6">Marketing</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-4 mb-3">
                                                <div class="form-group">
                                                    <label for="jobType">Type :</label>
                                                    <select id="jobType" class="custom-select">
                                                        <option value="type1">Full-time</option>
                                                        <option value="type3">Part-time</option>
                                                        <option value="type4">Remote</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-4 mb-3">
                                                <div class="form-group">
                                                    <label for="jobLocation">Location : </label>
                                                    <select id="jobLocation" class="custom-select">
                                                        <option value="location1">Chicago, US</option>
                                                        <option value="location3">Michigan, US</option>
                                                        <option value="location2">New York, US</option>
                                                        <option value="location4">Los Angles, US</option>
                                                        <option value="location5">Moscow, Russia</option>
                                                        <option value="location6">Sydney, Australia</option>
                                                        <option value="location7">Birmingham, UK</option>
                                                        <option value="location8">Manchester, UK</option>
                                                        <option value="location9">Beijing, China</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </form>

                                    <div class="job-list__wrapper mb-6">
                                        <h3 class="mb-4">Design</h3>

                                        <a href="career-single.html" class="card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                                            <div class="card-body">
                                                <span class="row justify-content-between align-items-center">
                                                    <span class="col-md-5 color--heading">
                                                        <span class="badge badge-circle background--danger text-white mr-3">GD</span> Senior Graphic Designer
                                                    </span>

                                                    <span class="col-5 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-clock mr-1"></i> Full time
                                                    </span>

                                                    <span class="col-7 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-map-marker-alt mr-1"></i> San Fransisco, US
                                                    </span>

                                                    <span class="d-none d-md-block col-1 text-center color--text">
                                                        <small><i class="fa fa-chevron-right"></i></small>
                                                    </span>
                                                </span>
                                            </div>
                                        </a>

                                        <a href="career-single.html" class="card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                                            <div class="card-body">
                                                <span class="row justify-content-between align-items-center">
                                                    <span class="col-md-5 color--heading">
                                                        <span class="badge badge-circle background--tertiary text-white mr-3">UX</span> UI/UX Designer
                                                    </span>

                                                    <span class="col-5 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-clock mr-1"></i> Remote
                                                    </span>

                                                    <span class="col-7 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-map-marker-alt mr-1"></i> Anywhere
                                                    </span>

                                                    <span class="d-none d-md-block col-1 text-center color--text">
                                                        <small><i class="fa fa-chevron-right"></i></small>
                                                    </span>
                                                </span>
                                            </div>
                                        </a>

                                        <a href="career-single.html" class="card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                                            <div class="card-body">
                                                <span class="row justify-content-between align-items-center">
                                                    <span class="col-md-5 color--heading">
                                                        <span class="badge badge-circle background--warning text-white mr-3">AN</span> Multimedia Artist &amp; Animator
                                                    </span>

                                                    <span class="col-5 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-clock mr-1"></i> Full time
                                                    </span>

                                                    <span class="col-7 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-map-marker-alt mr-1"></i> Birmingham, UK
                                                    </span>

                                                    <span class="d-none d-md-block col-1 text-center color--text">
                                                        <small><i class="fa fa-chevron-right"></i></small>
                                                    </span>
                                                </span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="job-list__wrapper mb-6">
                                        <h3 class="mb-4">Development</h3>

                                        <a href="career-single.html" class="card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                                            <div class="card-body">
                                                <span class="row justify-content-between align-items-center">
                                                    <span class="col-md-5 color--heading">
                                                        <span class="badge badge-circle background--success text-white mr-3">FE</span> Front End Developer
                                                    </span>

                                                    <span class="col-5 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-clock mr-1"></i> Part time
                                                    </span>

                                                    <span class="col-7 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-map-marker-alt mr-1"></i> Sydney, AU
                                                    </span>

                                                    <span class="d-none d-md-block col-1 text-center color--text">
                                                        <small><i class="fa fa-chevron-right"></i></small>
                                                    </span>
                                                </span>
                                            </div>
                                        </a>

                                        <a href="career-single.html" class="card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                                            <div class="card-body">
                                                <span class="row justify-content-between align-items-center">
                                                    <span class="col-md-5 color--heading">
                                                        <span class="badge badge-circle background--warning2 text-white mr-3">MD</span> Mobile Developer
                                                    </span>

                                                    <span class="col-5 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-clock mr-1"></i> Full-time
                                                    </span>

                                                    <span class="col-7 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-map-marker-alt mr-1"></i> San Fransisco, US
                                                    </span>

                                                    <span class="d-none d-md-block col-1 text-center color--text">
                                                        <small><i class="fa fa-chevron-right"></i></small>
                                                    </span>
                                                </span>
                                            </div>
                                        </a>

                                        <a href="career-single.html" class="card p-0 mb-3 border-0 shadow-sm shadow--on-hover">
                                            <div class="card-body">
                                                <span class="row justify-content-between align-items-center">
                                                    <span class="col-md-5 color--heading">
                                                        <span class="badge badge-circle background--info text-white mr-3">NT</span> .NET Developer
                                                    </span>

                                                    <span class="col-5 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-clock mr-1"></i> Full time
                                                    </span>

                                                    <span class="col-7 col-md-3 my-3 my-sm-0 color--text">
                                                        <i class="fas fa-map-marker-alt mr-1"></i> Manchester, UK
                                                    </span>

                                                    <span class="d-none d-md-block col-1 text-center color--text">
                                                        <small><i class="fa fa-chevron-right"></i></small>
                                                    </span>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                <hr />
                <Footer />
            </>
        )
    }
}

export default Carrer;