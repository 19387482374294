import Header from "../header";
import Footer from "../footer";
import { BackPain } from "./OurServiceEnum/backPainEnum";
import "./styling/contentStyle.css";
import { Helmet } from "react-helmet";
import backpainandneckpain from "../../../Images/postualtherapy.jpg";
import Understanding from "../../../Images/understanding.jpg";
import WhyChooseUs from "../../../Images/ChooseUs.jpg";
import Comprehensive from "../../../Images/Comprehensive.jpg";
import FirstStep from "../../../Images/FirstStep.jpg";
export default () => {
  return (
    <div>
      <Helmet>
        <title>
          Back Pain Physiotherapy in Lucknow, Neck Pain Physiotherapy in Lucknow
        </title>
        <meta
          name="description"
          content="Get effective back pain and neck pain physiotherapy in Lucknow. Our expert physiotherapists offer personalized treatments to relieve your back & neck pain and improve your quality of life."
        ></meta>
        <link
          rel="canonical"
          href="https://etsquareshealth.com/back-pain-neck-pain-physiotherapy-lucknow"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h1 style={{ color: "white" }}>
                    Back Pain And Neck Pain Physiotherapy
                  </h1>
                </li>
              </ul>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{BackPain.BACK_PAIN_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: BackPain.BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={backpainandneckpain}
                  className="img-fluid"
                  alt="back-neck-pain-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={Understanding}
                  className="img-fluid"
                  alt="understanding-physiotherapy"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {BackPain.BACK_PAIN_UNDERSTANDING_HEADER_TEXT}
                </h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: BackPain.BACK_PAIN_UNDERSTANDING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {BackPain.COMPREHENSIVE_BACK_PAIN_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: BackPain.COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Comprehensive}
                  className="img-fluid"
                  alt="comprehensive-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={WhyChooseUs}
                  className="img-fluid"
                  alt="why-choose-us"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{BackPain.WHY_CHOOSE_US_HEADER_TEXT}</h2>
                <p>{BackPain.WHY_CHOOSE_US_SUBHEADER_TEXT}</p>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: BackPain.WHY_CHOOSE_US_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{BackPain.FIRST_STEP_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: BackPain.FIRST_STEP_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img src={FirstStep} className="img-fluid" alt="first-step" />
              </div>
            </div>
          </div>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h5 style={{ color: "white" }}>
                    {BackPain.FAQS_HEADER_TEXT}
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div style={{ marginLeft: "20px" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: BackPain.FAQS_CONTENT_TEXT,
                }}
              ></p>
            </div>
          </div>
        </main>
        <hr />
        <Footer />
      </>
    </div>
  );
};
