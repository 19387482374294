import "./whatWeTreat.css";
export default () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="panel panel-primary">
          <div className="panel-heading">
            <h4 className="panel-title" style={{ color: "white" }}>
              <i className="fa fa-stethoscope" style={{ color: "white" }}></i>
              &nbsp; What We Treat
            </h4>
          </div>
          <div className="panel-body">
            <table className="table table-responsive table-hover">
              <thead>
                <tr>
                  <th>
                    <h6>
                      <b>Conditions</b>
                    </h6>
                  </th>
                  <th>
                    <h6>
                      <b>Symptoms</b>
                    </h6>
                  </th>
                  <th>
                    <h6>
                      <b>Therapies</b>
                    </h6>
                  </th>
                  <th>
                    <h6>
                      <b>Services</b>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lumbar Spondylosis</td>
                  <td>Muscle Stiffness</td>
                  <td>
                    Interferential Therapy <b>(IFT)</b>
                  </td>
                  <td>Chiropractor Treatment</td>
                </tr>
                <tr>
                  <td>Pes Anserine Bursitis</td>
                  <td>Muscle Spasm</td>
                  <td>Chiropractic Therapy</td>
                  <td>Sports Physiotherapy</td>
                </tr>
                <tr>
                  <td>Quadriceps Muscle Strain</td>
                  <td>Crepitus - Cracking Joints</td>
                  <td>Ultrasound Therapy</td>
                  <td>Pediatric Physiotherapy</td>
                </tr>
                <tr>
                  <td>Herniated Disk Or Slipped Disc</td>
                  <td>Numbness And Tingling</td>
                  <td>Laser Therapy</td>
                  <td>Home Care Physiotherapy</td>
                </tr>
                <tr>
                  <td>
                    Total Hip Replacement <b>(THR)</b>
                  </td>
                  <td>Neck Pain</td>
                  <td>Traction Therapy</td>
                  <td>Neuro Physiotherapy - Rehab</td>
                </tr>
                <tr>
                  <td>Clubfoot /Congenital Talipes Equinovarus/CTEV</td>
                  <td>Foot Pain</td>
                  <td>Wax Therapy</td>
                  <td>Pre And Post Surgery Rehabilitation</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
