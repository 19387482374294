import FaqThree from "../Toggle/FaqThree";
import { ContentDetails } from "../../Enums/contentEnum";
export default () => {
  const data = [
    {
      title: ContentDetails.RIGHT_PHYSIO_HEADING,
      description: ContentDetails.RIGHT_PHYSIO_TEXT,
    },
    {
      title: ContentDetails.PHYSIO_ASSIGNED_HEADING,
      description: ContentDetails.PHYSIO_ASSIGNED_TEXT,
    },
    {
      title: ContentDetails.EXERCISE_ON_OWN_HEADING,
      description: ContentDetails.EXERCISE_ON_OWN_TEXT,
    },
    {
      title: ContentDetails.CHIROPACTOR_DIFFERENT_HEADING,
      description: ContentDetails.CHIROPACTOR_DIFFERENT_TEXT,
    },
  ];
  return (
    <>
      {data.map((d) => {
        return <FaqThree title={d.title} description={d.description} />;
      })}
    </>
  );
};
