import React, { Component } from "react";
import { fillContactUsData } from "../../Share/Action/Contact.Action";
import { connect } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import "../Style/contact.css";
import { Helmet } from "react-helmet";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientName: "",
      email: "",
      mobileNumber: "",
      remark: "",
      successMessage: "",
      fieldValidation: false,
    };
  }

  updateFields = (e) => {
    try {
      const { name, value } = e.target;
      this.setState({
        [name]: value,
        key: name,
        successMessage: "",
        fieldValidation: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  contactFieldValidation = () => {
    const { patientName, email, mobileNumber, remark } = this.state;
    if (patientName.length === 0) {
      this.setState({
        successMessage: "Please enter patient name..",
        fieldValidation: true,
      });
    } else if (email.length === 0) {
      this.setState({
        successMessage: "please enter email..",
        fieldValidation: true,
      });
    } else if (mobileNumber.length === 0) {
      this.setState({
        successMessage: "Please enter mobile number..",
        fieldValidation: true,
      });
    } else if (remark.length === 0) {
      this.setState({
        successMessage: "Please enter message/feedback..",
        fieldValidation: true,
      });
    }
  };

  completeContactUsDetails = () => {
    try {
      const { patientName, email, mobileNumber, remark, fieldValidation } =
        this.state;
      this.contactFieldValidation();
      const contactRequest = {
        patientName: patientName,
        email: email,
        mobileNumber: mobileNumber,
        remark: remark,
      };
      if (!fieldValidation) {
        this.props.fillContactUsData(contactRequest);

        this.setState({
          patientName: "",
          email: "",
          mobileNumber: "",
          remark: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { patientName, email, mobileNumber, remark, successMessage } =
      this.state;
    return (
      <>
        <div>
          <Helmet>
            <title>Contact Us- ETSquares Health</title>
            <meta name="description" content=""></meta>
          </Helmet>
          <Header />
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>Contact</li>
              </ul>
            </div>
          </div>
          <div className="container margin_60">
            <div className="container">
              <div className="row">
                {this.props.contacts.data &&
                  this.props.contacts.data.status && (
                    <div className="col-sm-12">
                      <label
                        style={{
                          color: this.props.contacts.data.status
                            ? "green"
                            : "red",
                        }}
                      >
                        {this.props.contacts.data.message}
                      </label>
                    </div>
                  )}
                {this.state.fieldValidation && (
                  <div className="col-sm-12">
                    <label style={{ color: "red" }}>{successMessage}</label>
                  </div>
                )}

                <div className="col-sm-12">
                  <div
                    className="col-sm-6"
                    style={{ float: "right", height: "280px" }}
                  >
                    <iframe
                      src={
                        "https://maps.google.com/maps?q=ETSquares+Health+Physiotherapy+%26+Sports+Rehabilitation+Clinic+-Mohibullapur&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      }
                      width="700"
                      height="600px"
                      style={{ border: "0px" }}
                      loading="lazy"
                    ></iframe>
                  </div>

                  <div className="col-sm-6" style={{ float: "left" }}>
                    <div className="contact-form">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.updateFields}
                          value={patientName}
                          id="name"
                          name="patientName"
                          placeholder="Name"
                          required=""
                          autoFocus=""
                        />
                      </div>

                      <div className="form-group form_left">
                        <input
                          type="email"
                          className="form-control"
                          onChange={this.updateFields}
                          value={email}
                          id="email"
                          name="email"
                          placeholder="Email"
                          required=""
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.updateFields}
                          id="phone"
                          value={mobileNumber}
                          name="mobileNumber"
                          maxLength="10"
                          placeholder="Mobile No."
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control textarea-contact"
                          onChange={this.updateFields}
                          rows="5"
                          value={remark}
                          id="comment"
                          name="remark"
                          placeholder="Type Your Message/Feedback here..."
                          required=""
                        ></textarea>
                        <br />
                        <button
                          className="btn btn-default btn-send"
                          onClick={() => this.completeContactUsDetails()}
                        >
                          {" "}
                          <span className="glyphicon glyphicon-send"></span>{" "}
                          Help Me{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container second-portion">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <div className="box">
                      <div className="icon">
                        <div className="image">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <div className="info">
                          <h3 className="title" style={{ marginTop: "15px" }}>
                            MAIL & WEBSITE
                          </h3>
                          <p>
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp; etsquareshealth@gmail.com
                            <br />
                            <br />
                            <i
                              className="fa fa-globe"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp;
                            <a
                              href="https://etsquareshealth.com/?fbclid=IwAR2Rw1zEPhemqkDFJBAVexLLB4MYhFuaKgdEUdw11D428_p8OUVcofGsV5k"
                              target={"_blank"}
                            >
                              etsquareshealth.com
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="space"></div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <div className="box">
                      <div className="icon">
                        <div className="image">
                          <i className="fa fa-mobile" aria-hidden="true"></i>
                        </div>
                        <div className="info">
                          <h3 className="title" style={{ marginTop: "15px" }}>
                            CONTACT
                          </h3>
                          <p>
                            <i className="fa fa-mobile" aria-hidden="true"></i>{" "}
                            &nbsp; (+91) - 8881131781
                            <br />
                            <br />
                            <i
                              className="fa fa-mobile"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp; (+91) - 8881131782
                          </p>
                        </div>
                      </div>
                      <div className="space"></div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <div className="box">
                      <div className="icon">
                        <div className="image">
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div className="info">
                          <h3 className="title" style={{ marginTop: "15px" }}>
                            ADDRESS
                          </h3>
                          <p>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp; A-308 Madhav Green City, Near Amity
                            University, Lucknow UttarPradesh - 226010
                          </p>
                          <p>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp; Shop No 19, Infront of, LGF Sai Star Complex Bank of Baroda, 
                            Railway Station, Bal Nikunj V M Pur, Mohibullapur, Lucknow, Uttar Pradesh 226021
                          </p>
                          <p>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp; 5/27, Vikaskhand 5, Gwari Village, Vikas Khand, Gomti Nagar, Lucknow, Uttar Pradesh 226010
                          </p>
                          <p>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp; Phase 1, House Number 12, Sita Vihar Colony, Jankipuram, Lucknow, Uttar Pradesh 226021
                          </p>
                        </div>
                      </div>
                      <div className="space"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { contacts } = state;
  return {
    contacts: contacts.contacts,
    contact: contacts.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fillContactUsData: (contactRequest) =>
      dispatch(fillContactUsData(contactRequest)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
