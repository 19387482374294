import "./healthSpecialist.css";
import needle from "../../../Images/needdle.jpg";
import cupping from "../../../Images/cuppingtherapy.jpg";
import postual from "../../../Images/postualtherapy.jpg";
export default () => {
  return (
    <div className="container">
      <div className="row mt-30">
        <div className="col-md-4 col-sm-6">
          <div className="box3">
            <img className="pic-1" src={needle} alt="needletherapy" />
            <div className="box-content">
              <h3 className="title">Needle Therapy</h3>
              <span className="post">Dry Needling Process</span>
              <p className="description">
                Trigger-point dry needling is an invasive procedure where a fine
                needle or acupuncture needle is inserted into the skin and
                muscle.
              </p>
              <ul className="icon">
                <li>
                  <a href="#" className="fa fa-search"></a>
                </li>
                <li>
                  <a href="#" className="fa fa-link"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="box3">
            <img className="pic-1" src={cupping} alt="cuppingtherapy" />
            <div className="box-content">
              <h3 className="title">Cupping Therapy</h3>
              <span className="post">Cupping</span>
              <p className="description">
                Cupping therapy is an ancient form of alternative medicine in
                which a therapist puts special cups on your skin for a few
                minutes to create suction. People get it for many purposes,
                including to help with pain, inflammation, blood flow,
                relaxation and well-being, and as a type of deep-tissue massage.
              </p>
              <ul className="icon">
                <li>
                  <a href="#" className="fa fa-search"></a>
                </li>
                <li>
                  <a href="#" className="fa fa-link"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="box3">
            <img className="pic-1" src={postual} alt="posturaltherapy" />
            <div className="box-content">
              <h3 className="title">Postual Therapy </h3>
              <span className="post">Posture Correction</span>
              <p className="description">
                Your pain exists because your body is out of balance. It could
                be the result of an old injury, hours of sitting behind a desk
                or the wheel of a car, repetitive use injuries from exercise or
                work, or simply a postural dysfunction such as scoliosis. All of
                the can knock your body out of perfect alignment, causing pain,
                sleeping troubles and limitations.
              </p>
              <ul className="icon">
                <li>
                  <a href="#" className="fa fa-search"></a>
                </li>
                <li>
                  <a href="#" className="fa fa-link"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
