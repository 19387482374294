import Header from "../header";
import Footer from "../footer";
import { SportsPT } from "./OurServiceEnum/sportsptenum";
import "./styling/contentStyle.css";
import { Helmet } from "react-helmet";
import SportsPhysiotherapy from "../../../Images/SportsPhysiotherapy.jpg";
import WhyChooseUs from "../../../Images/ChooseUs.jpg";
import HealthExcellence from "../../../Images/HealthExcellence.jpg";
import HealthApproach from "../../../Images/HealthApproach.jpg";

export default () => {
  return (
    <div>
      <Helmet>
        <title>
          Best Sports Physiotherapy Clinic in Lucknow - ETSquares Health
        </title>
        <meta
          name="description"
          content="Discover the best sports physiotherapy clinic in Lucknow. Our skilled physiotherapists specialize in sports-related injuries, providing personalized treatments to help you recover quickly."
        ></meta>
        <link
          rel="canonical"
          href="https://etsquareshealth.com/best-sports-physiotherapy-clinic-lucknow"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h1 style={{ color: "white" }}>Sports Physiotherapy</h1>
                </li>
              </ul>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{SportsPT.BACK_PAIN_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: SportsPT.BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={SportsPhysiotherapy}
                  className="img-fluid"
                  alt="sports-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={WhyChooseUs}
                  className="img-fluid"
                  alt="why-choose-us"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {SportsPT.BACK_PAIN_UNDERSTANDING_HEADER_TEXT}
                </h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: SportsPT.BACK_PAIN_UNDERSTANDING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {SportsPT.COMPREHENSIVE_BACK_PAIN_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: SportsPT.COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={HealthApproach}
                  className="img-fluid"
                  alt="health-approach"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={HealthExcellence}
                  className="img-fluid"
                  alt="health-excellence"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{SportsPT.WHY_CHOOSE_US_HEADER_TEXT}</h2>
                <p>{SportsPT.WHY_CHOOSE_US_SUBHEADER_TEXT}</p>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: SportsPT.WHY_CHOOSE_US_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          {/* <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{SportsPT.FAQS_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: SportsPT.FAQS_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={FrequentlyAskedQuestion}
                  className="img-fluid"
                  alt="frequently-asked-questions"
                />
              </div>
            </div>
          </div> */}
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h5 style={{ color: "white" }}>
                    {SportsPT.FAQS_HEADER_TEXT}
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: SportsPT.FAQS_CONTENT_TEXT,
              }}
            ></p>
          </div>
        </main>
        <hr />
        <Footer />
      </>
    </div>
  );
};
