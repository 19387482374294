import FaqFour from "../Toggle/FaqFour";
import { ContentDetails } from "../../Enums/contentEnum";
export default () => {
  const data = [
    {
      title: ContentDetails.PHYSIOTHERAPY_SERVICE_HEADING,
      description: ContentDetails.PHYSIOTHERAPY_SERVICE_TEXT,
    },
    {
      title: ContentDetails.PHYSIOTHERAPY_NEAR_HOME_HEADING,
      description: ContentDetails.PHYSIOTHERAPY_NEAR_HOME_TEXT,
    },
    {
      title: ContentDetails.DURATION_EACH_SESSION_HEADING,
      description: ContentDetails.DURATION_EACH_SESSION_TEXT,
    },
    {
      title: ContentDetails.SESSION_FREQUENCY_HEADING,
      description: ContentDetails.SESSION_FREQUENCY_TEXT,
    },
  ];
  return (
    <>
      {data.map((d) => {
        return <FaqFour title={d.title} description={d.description} />;
      })}
    </>
  );
};
