import Header from "../header";
import Footer from "../footer";
import { Physiotherapy } from "./OurServiceEnum/physiotherapyenum";
import "./styling/contentStyle.css";
import { Helmet } from "react-helmet";
import physiotherapy from "../../../Images/neuro.jpg";
import WhyChooseUs from "../../../Images/ChooseUs.jpg";
import Comprehensive from "../../../Images/Comprehensive.jpg";
import FirstStep from "../../../Images/FirstStep.jpg";
import HealthApproach from "../../../Images/HealthApproach.jpg";
import LocationScheduling from "../../../Images/LocationScheduling.jpg";
import EdgeTechniques from "../../../Images/EdgeTechniques.jpg";
export default () => {
  return (
    <div>
      <Helmet>
        <title>
          Best Physiotherapy in Lucknow, Best Physiotherapy Clinic in Lucknow
        </title>
        <meta
          name="description"
          content="Looking for the best physiotherapy in Lucknow? ETSquares Health is one of the best physiotherapy clinics in Lucknow that provide top-notch physiotherapy services."
        ></meta>
        <link
          rel="canonical"
          href="https://etsquareshealth.com/best-physiotherapy-lucknow"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h1 style={{ color: "white" }}>Physiotherapy</h1>
                </li>
              </ul>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Physiotherapy.BACK_PAIN_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={physiotherapy}
                  className="img-fluid"
                  alt="physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={WhyChooseUs}
                  className="img-fluid"
                  alt="why-choose-us"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {Physiotherapy.BACK_PAIN_UNDERSTANDING_HEADER_TEXT}
                </h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.BACK_PAIN_UNDERSTANDING_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {Physiotherapy.COMPREHENSIVE_BACK_PAIN_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.COMPREHENSIVE_BACK_PAIN_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Comprehensive}
                  className="img-fluid"
                  alt="comprehensive-physiotherapy"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={EdgeTechniques}
                  className="img-fluid"
                  alt="edge-techniques"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {Physiotherapy.WHY_CHOOSE_US_HEADER_TEXT}
                </h2>
                <p>{Physiotherapy.WHY_CHOOSE_US_SUBHEADER_TEXT}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.WHY_CHOOSE_US_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Physiotherapy.FIRST_STEP_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.FIRST_STEP_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={HealthApproach}
                  className="img-fluid"
                  alt="health-approach"
                />
              </div>
            </div>
          </div>

          {/* <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img
                  src={HealthTeam}
                  className="img-fluid"
                  alt="our-health-team"
                />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Physiotherapy.MEET_TEAM_HEADER_TEXT}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.MEET_TEAM_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div> */}

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Physiotherapy.LOCATION_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.LOCATION_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={LocationScheduling}
                  className="img-fluid"
                  alt="location-scheduling"
                />
              </div>
            </div>
          </div>

          <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <img src={FirstStep} className="img-fluid" alt="first-step" />
              </div>
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">
                  {Physiotherapy.BETTER_HEALTH_HEADER_TEXT}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.BETTER_HEALTH_CONTENT_TEXT,
                  }}
                ></p>
              </div>
            </div>
          </div>

          {/* <div className="container py-lg-3 mt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 book-info">
                <h2 className="mb-4">{Physiotherapy.FAQS_HEADER_TEXT}</h2>
                <p
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: Physiotherapy.FAQS_CONTENT_TEXT,
                  }}
                ></p>
              </div>
              <div className="col-sm-6">
                <img
                  src={FrequentlyAskedQuestion}
                  className="img-fluid"
                  alt="frequently-asked-questions"
                />
              </div>
            </div>
          </div> */}
          <div id="breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <h5 style={{ color: "white" }}>
                    {Physiotherapy.FAQS_HEADER_TEXT}
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: Physiotherapy.FAQS_CONTENT_TEXT,
              }}
            ></p>
          </div>
        </main>
        <hr />
        <Footer />
      </>
    </div>
  );
};
