import Header from "./header";
import Footer from "./footer";
import FaqOne from "./ToggleMain/ToggleFaqOne";
import FaqThree from "./ToggleMain/ToggleFaqThree";
import FaqTwo from "./ToggleMain/ToggleFaqTwo";
import FaqFour from "./ToggleMain/ToggleFaqFour";
import FaqFive from "./ToggleMain/ToggleFaqFive";
import "../Style/faq.css";
import { Helmet } from "react-helmet";
export default () => {
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    console.log(id);
    switch (id) {
      case "choosing":
        document.getElementById("choosing_1").style.display = "block";
        document.getElementById("clinic_1").style.display = "none";
        document.getElementById("home_1").style.display = "none";
        document.getElementById("payment_1").style.display = "none";
        break;
      case "clinic":
        document.getElementById("clinic_1").style.display = "block";
        document.getElementById("home_1").style.display = "none";
        document.getElementById("payment_1").style.display = "none";
        document.getElementById("choosing_1").style.display = "none";
        break;
      case "home":
        document.getElementById("home_1").style.display = "block";
        document.getElementById("clinic_1").style.display = "none";
        document.getElementById("payment_1").style.display = "none";
        document.getElementById("choosing_1").style.display = "none";
        break;
      case "payment":
        document.getElementById("payment_1").style.display = "block";
        document.getElementById("clinic_1").style.display = "none";
        document.getElementById("home_1").style.display = "none";
        document.getElementById("choosing_1").style.display = "none";
        break;
      default:
        break;
    }
    element.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickScrollOne = (id) => {
    const element = document.getElementById("list");
    document.getElementById(id).style.display = "none";
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div>
        <Helmet>
          <title>FQA - ETSquares Health</title>
          <meta
            name="description"
            content="Get relief from migraines with our specialized migraine physiotherapy clinic in Lucknow. Our skilled physiotherapists offer personalized treatments to help manage migraine symptoms."
          ></meta>
        </Helmet>
        <Header />
        <div id="breadcrumb">
          <div className="container">
            <ul>
              <li>Frequently Asked Questions</li>
            </ul>
          </div>
        </div>
        <div className="container margin_50">
          <div className="container">
            <div className="row">
              <div className="col-md-4" id="list">
                <div className="panel-group">
                  <div className="faqHeader" style={{ cursor: "pointer" }}>
                    <i className="fa fa-info-circle"></i>&nbsp;About
                  </div>
                </div>

                <div className="panel-group">
                  <div
                    className="faqHeader"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClickScroll("choosing");
                    }}
                  >
                    <i className="fa fa-check"></i>&nbsp;Choosing
                  </div>
                </div>

                <div className="panel-group">
                  <div
                    className="faqHeader"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClickScroll("clinic");
                    }}
                  >
                    <i className="fa fa-medkit"></i>&nbsp;Clinic
                  </div>
                </div>

                <div className="panel-group">
                  <div
                    className="faqHeader"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClickScroll("home");
                    }}
                  >
                    <i className="fa fa-home"></i>&nbsp;Home Service
                  </div>
                </div>

                <div className="panel-group">
                  <div
                    className="faqHeader"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClickScroll("payment");
                    }}
                  >
                    <i className="fa fa-credit-card"></i>&nbsp;Payment
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="panel-group" id="about">
                  <div className="faqHeader">
                    <i className="fa fa-info-circle"></i>&nbsp;About
                    Physiotherapy
                  </div>
                  <FaqOne />
                </div>
                <div className="panel-group" id="choosing">
                  <div className="faqHeader">
                    <i className="fa fa-check"></i>&nbsp;Choosing
                    Physiotherapist
                    <i
                      className="fa fa-arrow-up"
                      id="choosing_1"
                      onClick={() => {
                        handleClickScrollOne("choosing_1");
                      }}
                      style={{
                        float: "right",
                        cursor: "pointer",
                        display: "none",
                      }}
                    ></i>
                  </div>
                  <FaqThree />
                </div>
                <div className="panel-group" id="clinic">
                  <div className="faqHeader">
                    <i className="fa fa-medkit"></i>&nbsp;Physiotherapy at
                    Clinic
                    <i
                      className="fa fa-arrow-up"
                      id="clinic_1"
                      onClick={() => {
                        handleClickScrollOne("clinic_1");
                      }}
                      style={{
                        float: "right",
                        cursor: "pointer",
                        display: "none",
                      }}
                    ></i>
                  </div>
                  <FaqTwo />
                </div>
                <div className="panel-group" id="home">
                  <div className="faqHeader">
                    <i className="fa fa-home"></i>&nbsp;Physiotherapy at Home
                    <i
                      className="fa fa-arrow-up"
                      id="home_1"
                      onClick={() => {
                        handleClickScrollOne("home_1");
                      }}
                      style={{
                        float: "right",
                        cursor: "pointer",
                        display: "none",
                      }}
                    ></i>
                  </div>
                  <FaqFour />
                </div>
                <div className="panel-group" id="payment">
                  <div className="faqHeader">
                    <i className="fa fa-credit-card"></i>&nbsp;Payment & Billing
                    <i
                      className="fa fa-arrow-up"
                      id="payment_1"
                      onClick={() => {
                        handleClickScrollOne("payment_1");
                      }}
                      style={{
                        float: "right",
                        cursor: "pointer",
                        display: "none",
                      }}
                    ></i>
                  </div>
                  <FaqFive />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <Footer />
      </div>
    </>
  );
};
